/**
 * Global Prosemirror styles that are applied to each card's content
 */

// NB: Font sizes need to be expressed in "em" units, otherwise they will not scale
// relative to their container
import { SystemStyleObject } from '@chakra-ui/system'
import { last } from 'lodash'

import { isMobileDevice } from 'utils/deviceDetection'
import { DRAGOVER_CLASS } from 'utils/hooks/useDroppable'

import { CARD_GRID_GUTTER } from '../extensions/Card/constants'
import { fontStyles } from '../extensions/Font/fontStyles'
import { HANDLE_HOVERING_ATTR } from '../extensions/GlobalDragHandle/GlobalDragHandlePlugin'
import { mathStyles } from '../extensions/Math/mathStyles'
import { SmartLayoutStyles } from '../extensions/SmartLayout/SmartLayoutStyles'
import {
  highlightStyles,
  paddedTextBackground,
} from '../extensions/TextColor/highlightStyles'
import { textColorStyles } from '../extensions/TextColor/textColorStyles'
import { BLOCK_MARGIN, FONT_SIZES } from './constants'
import { listStyles } from './listStyles'

const placeholderStyles = {
  content: 'attr(placeholder)',
  position: 'absolute',
  top: 0,
  opacity: 0.3,
  pointerEvents: 'none',
  left: 0,
  width: '100%',
}

export const CardWrapperStyles: SystemStyleObject = {
  '@media screen and (max-width: 30rem)': {
    // Necessary because Button does not yet support responsive sizes:
    // https://github.com/chakra-ui/chakra-ui/discussions/2276
    '.insert-card-button': {
      height: 6,
      width: 6,
      minW: 6,
      fontSize: 'xs',
    },
  },
}

export const CardContentStyles: SystemStyleObject = {
  height: '100%',
  outlineWidth: '0px',
  lineHeight: ['1.6 !important', 1.8],
  letterSpacing: '-.01em',
  fontSize: 'var(--font-size)',

  '--block-border-radius': 'var(--chakra-radii-md)',
  '--block-border-color': 'var(--chakra-colors-blackAlpha-300)',

  '--grid-outline-color': 'var(--chakra-colors-blackAlpha-200)',
  '--grid-border': '1px',
  '--grid-padding': `calc((${CARD_GRID_GUTTER}rem * var(--zoom-factor) - var(--grid-border)) / 2)`,

  '&.is-dark': {
    '--grid-outline-color': 'var(--chakra-colors-whiteAlpha-300)',
    '--block-border-color': 'var(--chakra-colors-whiteAlpha-500)',

    code: {
      backgroundColor: 'gray.800',
      color: 'gray.100',
    },
    '.block-blockquote': {
      blockquote: {
        borderLeftColor: 'var(--accent-color)',
      },
    },
    ...highlightStyles.dark,
    ...textColorStyles.dark,
  },

  // Class added to every block within a card via the BlockClass extension
  '.block': {
    my: BLOCK_MARGIN,
    fontFamily: 'var(--body-font)',
    fontWeight: 'normal',
    color: 'var(--body-color)',
  },

  '&.is-full-width': {
    '.block': {
      // Exceptions for nodes that can break out of parent
      '&:not(.block-image, .block-table, .block-gridLayout, .block-gallery, .block-video, .block-embed, .block-drawing)':
        {
          // Apply a max width
          maxWidth: 'var(--max-text-width)',
          ml: 'auto',
          mr: 'auto',
        },
    },
  },

  // Remove margin from the first block at the top level of the card, which is just inside a div added by Tiptap
  '> div > .block:first-child': {
    mt: '0em',
  },

  'a.link': {
    color: 'var(--link-color)',
    cursor: 'pointer',
    fontWeight: 'bold',
    textDecoration: 'underline',
    '&[href=""]': {
      textDecorationStyle: 'dashed',
    },
    '&:hover': {
      color: 'var(--link-color-hover)',
    },
    '&:active': {
      color: 'var(--link-color-hover)',
    },
  },

  '.block-expandableToggle': {
    '&.is-selecting-node': {
      '.expandableToggle': {
        boxShadow: 'outline',
      },
    },

    '.node-expandableSummary': {
      '&.is-empty': {
        '[placeholder]': {
          _before: placeholderStyles,
        },
      },
    },

    '[data-open="false"]': {
      '[data-node-view-content-inner="expandableToggle"]': {
        '> :not(:first-child)': {
          display: 'none',
        },
      },
    },
  },

  '.block-heading': {
    fontWeight: 'bold',
    lineHeight: 1.25,
    my: ['1em', '1.5em'],
    'h1, h2, h3': {
      position: 'relative',
      fontFamily: 'var(--heading-font)',
      color: 'var(--heading-color)',
    },
    '&.is-focused-inside.is-empty': {
      'h1, h2, h3': {
        '&[placeholder]::before': placeholderStyles,
      },
    },
  },
  h1: {
    fontSize: FONT_SIZES.h1,
    '@media print': {
      fontSize: last(FONT_SIZES.h1),
    },
  },
  h2: {
    fontSize: FONT_SIZES.h2,
    '@media print': {
      fontSize: last(FONT_SIZES.h2),
    },
  },
  h3: {
    fontSize: FONT_SIZES.h3,
    '@media print': {
      fontSize: last(FONT_SIZES.h3),
    },
  },

  '.block-title': {
    my: '1.5em',

    '&.is-focused-inside.is-empty': {
      '.title': {
        '&[placeholder]::before': placeholderStyles,
      },
    },

    '.title': {
      fontWeight: 'bold',
      position: 'relative',
      fontFamily: 'var(--heading-font)',
      color: 'var(--heading-color)',
      fontSize: FONT_SIZES.title,
      lineHeight: 1.2,
      '@media print': {
        fontSize: last(FONT_SIZES.title),
      },
    },
  },

  '.block-paragraph': {
    fontSize: FONT_SIZES.paragraph,
  },

  ...fontStyles,

  code: {
    backgroundColor: 'gray.100',
    color: 'gray.700',
    ...paddedTextBackground,
  },
  ...highlightStyles.default,
  ...textColorStyles.default,

  ...listStyles,

  ...mathStyles,

  '.block-codeBlock': {
    pre: {
      fontFamily: 'mono',
      fontSize: FONT_SIZES.codeBlock,
      '@media print': {
        fontSize: last(FONT_SIZES.codeBlock),
      },
      color: 'gray.800',
      background: 'gray.100',
      borderRadius: 'var(--block-border-radius)',
      overflow: 'hidden',
      code: {
        display: 'block',
        padding: '.75rem 1rem',
      },
    },
  },

  '.block-calloutBox': {
    '&.is-selected': {
      '.calloutBox': {
        boxShadow: 'var(--chakra-shadows-outline)',
      },
    },
  },

  '.block-blockquote': {
    '&.is-selected': {
      blockquote: {
        boxShadow: 'var(--chakra-shadows-outline)',
      },
    },
    blockquote: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: FONT_SIZES.blockquote,
      lineHeight: '1.35',
      borderLeftWidth: '3px',
      borderLeftStyle: 'solid',
      borderLeftColor: 'var(--accent-color)',
      p: 0,
      m: 0,
      px: '1em',
    },
  },

  '.block-drawing': {
    '&.is-focused': {
      '.drawing': {
        boxShadow: 'var(--chakra-shadows-outline)',
      },
    },
  },

  '.block-embed, .block-video': {
    '&.is-focused': {
      '.embed-preview, .embed-viewer, .video-player': {
        boxShadow: 'var(--chakra-shadows-outline)',
      },
    },
    _hover: {
      '.embed-preview': {
        bg: 'var(--accent-color-50)',
        borderColor: 'var(--accent-color-100)',
      },
    },
  },

  '.block-image': {
    '&.is-focused': {
      '.image, .placeholder': {
        boxShadow: 'var(--chakra-shadows-outline)',
      },
    },
  },

  '.block-contributors': {
    '.contributors-list': {
      fontSize: '1.25em',
      fontWeight: 'bold',
    },
    '&.is-focused': {
      '.contributors': {
        borderRadius: 'var(--block-border-radius)',
        boxShadow: 'var(--chakra-shadows-outline)',
      },
    },
  },

  '.mention': {
    bgColor: 'var(--accent-color-100)',
  },

  '.node-mention, .node-cardMention, .node-docMention': {
    '&.is-focused': {
      '.mention': {
        boxShadow: 'var(--chakra-shadows-outline)',
      },
    },
  },

  '.block-mediaPlaceholder': {
    '&.is-focused': {
      '.placeholder': {
        boxShadow: 'var(--chakra-shadows-outline)',
      },
    },
  },
  '.block-tableOfContents': {
    '&.is-focused': {
      borderRadius: 'var(--block-border-radius)',
      boxShadow: 'var(--chakra-shadows-outline)',
    },
  },

  '.block-button': {
    '&.is-focused': {
      '.button': {
        boxShadow: 'var(--chakra-shadows-outline)',
      },
    },
  },

  '.block-gallery': {
    // Pad out so images are flush with text and the border is as wide as columns
    // And fudge the vertical padding so galleries don't overlap each other but also
    // don't go too far from text
    mx: 'calc(-1 * var(--grid-padding))',
    my: 'calc(-0.25 * var(--grid-padding))',
    '.gallery': {
      px: 'var(--grid-padding)',
      py: 'calc(0.75 * var(--grid-padding))',
      border: '1px solid',
      borderColor: 'transparent',
      borderRadius: 'var(--block-border-radius)',

      '.add-image': {
        mb: 'calc(0.75 * var(--grid-padding))',
      },

      [`&.${DRAGOVER_CLASS}`]: {
        border: '1px dashed var(--chakra-colors-trueblue-300) !important',
        backgroundColor: 'var(--chakra-colors-trueblue-50) !important',
        // Our dragover logic doesn't work with children, so make sure
        // the placeholder won't take clicks while dragging
        '.gallery-placeholder': {
          pointerEvents: 'none',
        },
      },
    },

    '&.is-focused, &.is-empty': {
      '.gallery': {
        backgroundColor: 'blackAlpha.50',
      },
    },

    [`&.is-focused, &:hover, &[${HANDLE_HOVERING_ATTR}]`]: {
      '.gallery': {
        borderColor: 'var(--grid-outline-color)',
      },
      '.add-image': {
        opacity: 1,
      },
    },

    '&.is-selected': {
      '.gallery': {
        boxShadow: 'var(--chakra-shadows-outline)',
      },
    },
  },

  ...SmartLayoutStyles,

  '.block-gridLayout': {
    '&.is-selected': {
      '.grid-cell': {
        boxShadow: 'var(--chakra-shadows-outline)',
      },
    },
    // Offsets the padding inside the cells so text stays aligned
    mx: 'calc(-1 * var(--grid-padding))',
    '.layout-content': {
      display: 'contents',
      '> div': {
        display: 'contents',
      },
    },

    '.grid-cell': {
      position: 'relative',
      paddingX: 'var(--grid-padding)',
      outlineWidth: '0px', // Hidden by default
      outlineStyle: 'solid',
      outlineColor: 'var(--grid-outline-color)',

      '&:first-child': {
        borderLeftRadius: 'var(--block-border-radius)',
      },
      '&:last-child': {
        borderRightRadius: 'var(--block-border-radius)',
      },

      '.block-gallery': {
        my: '0',
      },
    },

    [`&.is-focused, &:hover, &[${HANDLE_HOVERING_ATTR}]`]: {
      '.layout-content[data-editable=true]': {
        '.grid-cell': {
          outlineWidth: 'var(--grid-border)',
        },
      },

      '.add-column': {
        opacity: 1,
      },
    },
  },

  '.block-table': {
    table: {
      shadow: `inset 0px 0px 0px 1px var(--block-border-color)`,
      borderRadius: 'var(--block-border-radius)',
      '> .table-content': {
        // Table content
        // This is a tbody so no need to change display
        '> div': {
          // Table content wrapper
          display: 'contents',
          '> .react-renderer': {
            // TableRow renderer
            display: 'contents',
            '.table-row-wrapper': {
              // TableRow wrapper
              '.table-row-content': {
                // TableRow content
                display: 'contents',
                '> div': {
                  // TableRow content wrapper
                  display: 'contents',
                },
                td: {
                  verticalAlign: 'top',

                  // dont show heading placeholder text in tables, will overflow too easily
                  '.block-heading [placeholder]::before': {
                    display: 'none',
                  },
                },
              },
            },
          },
        },
      },
    },
    '[data-node-view-content-inner="table"]': {
      '> :nth-child(even)': {
        td: {
          backgroundColor: 'blackAlpha.50',
        },
      },
      '> :nth-child(odd)': {
        td: {
          backgroundColor: 'whiteAlpha.50',
        },
      },
    },
    [`&.is-focused-inside`]: {
      '.table-wrapper': {
        shadow: 'none',
      },
      td: {
        borderColor: 'var(--grid-outline-color)',
      },
    },
    td: {
      minWidth: '3em',
      borderX: '1px',
      borderColor: 'transparent',
      paddingX: '1em',
      position: 'relative',
      '&.is-focused-inside': {
        boxShadow: 'inset var(--chakra-shadows-outline)',
      },
      '.block': {
        my: '0.75em',
        // DO NOT REMOVE THIS. This makes it so that any block child
        // of a table cell can't exceed 75% of the viewport width.
        maxWidth: isMobileDevice ? '75vw' : undefined,
      },
    },
    // Added by prosemirror-tables https://github.com/ProseMirror/prosemirror-tables/blob/6b16ed3cf306886f2c169aebbe60701e1ac2deac/src/cellselection.js
    // Based on https://www.tiptap.dev/examples/tables/
    /* Give selected cells a blue overlay */
    '.selectedCell:after': {
      zIndex: 2,
      position: 'absolute',
      content: '""',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: 'trueblue.100',
      pointerEvents: 'none',
      opacity: 0.4,
    },
  },
  '.tippy-arrow': {
    borderLeftColor: 'red',
  },

  '.footnote-label': {
    textDecoration: 'underline',
    textDecorationColor: 'var(--link-color)',
  },

  '.footnote': {
    color: 'var(--link-color)',
  },

  '.node-emoji': {
    // Ensures themed text blocks (like heading) show emojis properly
    color: 'initial',
  },
}

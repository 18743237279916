import {
  Accordion,
  AccordionButton,
  AccordionButtonProps,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import {
  duotone,
  regular,
} from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DOC_DISPLAY_NAME_PLURAL } from '@gamma-app/ui'
import { Dispatch, SetStateAction, useCallback } from 'react'

import { CSSinJSFormControl } from 'modules/code_editor/components/CSSinJSFormControl'
import {
  DEFAULT_ACCENT_COLOR,
  DEFAULT_BODY_COLOR,
  DEFAULT_BODY_COLOR_DARK,
  DEFAULT_FONTS,
  DEFAULT_HEADING_COLOR,
  DEFAULT_HEADING_COLOR_DARK,
} from 'modules/theming'
import { ContainerStylePanel } from 'modules/tiptap_editor/components/drawers/CardStyleDrawer'
import { BackgroundPanel } from 'modules/tiptap_editor/components/panels/BackgroundPanel'
import {
  DEFAULT_DOC_BACKGROUND,
  DEFAULT_THEME_BACKGROUND,
} from 'modules/tiptap_editor/styles/backgroundStyles'
import {
  ContainerEffect,
  DEFAULT_CONTAINER,
  getContainerBackgroundColor,
} from 'modules/tiptap_editor/styles/containerStyles'
import { useUserContext } from 'modules/user'

import { Theme } from '../types'
import { getThemeBackgroundOrDefault, isThemeDark } from '../utils'
import { FontPicker } from './FontPicker'
import { LogoPicker } from './LogoPicker'
import { TextColorFormControl } from './TextColorFormControl'

type ThemeConfigPanelProps = {
  theme: Theme
  updateTheme: (theme: Partial<Theme>) => void
  themeValidationError: string | null
  setThemeValidationError: Dispatch<SetStateAction<string | null>>
}

const accordionPanelStyles = {
  p: 6,
}

export const ThemeAccordionIcon = () => {
  return (
    <Box
      className="accordion-icon"
      transitionProperty="common"
      transitionDuration="normal"
      color="gray.800"
    >
      <FontAwesomeIcon size="xs" fixedWidth icon={regular('chevron-down')} />
    </Box>
  )
}

export type ThemeAccordionButtonProps = {
  icon: IconDefinition
  label: string
} & AccordionButtonProps

export const ThemeAccordionButton = ({
  icon,
  label,
  ...buttonProps
}: ThemeAccordionButtonProps) => {
  return (
    <AccordionButton
      sx={{
        _expanded: { '.accordion-icon': { transform: 'rotate(-180deg)' } },
      }}
      {...buttonProps}
    >
      <ThemeAccordionHeader label={label} icon={icon} />
      <ThemeAccordionIcon />
    </AccordionButton>
  )
}

export const ThemeAccordionHeader = ({ icon, label }) => {
  return (
    <HStack flex="1" textAlign="left" py="1.5" px="0">
      <Box color="trueblue.600">
        <FontAwesomeIcon fixedWidth icon={icon} />
      </Box>
      <Text fontWeight="600" fontSize="md">
        {label}
      </Text>
    </HStack>
  )
}

export const ThemeConfigPanel = ({
  theme,
  updateTheme,
  themeValidationError,
  setThemeValidationError,
}: ThemeConfigPanelProps) => {
  const updateThemeConfig = useCallback(
    (newConfig: Partial<Theme['config']>) => {
      updateTheme({ config: { ...theme.config, ...newConfig } })
    },
    [updateTheme, theme.config]
  )

  const updateAccentColor = useCallback(
    (color: string | null) => updateTheme({ accentColor: color ?? undefined }),
    [updateTheme]
  )
  const updateHeadingColor = useCallback(
    (color: string | null) =>
      updateThemeConfig({ headingColor: color ?? undefined }),
    [updateThemeConfig]
  )
  const updateBodyColor = useCallback(
    (color: string | null) =>
      updateThemeConfig({ bodyColor: color ?? undefined }),
    [updateThemeConfig]
  )

  const isDark = isThemeDark(theme)
  const cardBackgroundColor = getContainerBackgroundColor(theme)
  const background = getThemeBackgroundOrDefault(theme)
  const { isGammaOrgUser } = useUserContext()

  return (
    <Stack spacing={4}>
      <FormControl
        isInvalid={Boolean(themeValidationError)}
        px={4}
        pt={4}
        pb={2}
      >
        <FormLabel>Theme name</FormLabel>
        <Input
          type="text"
          placeholder="Untitled theme"
          value={theme.name}
          onChange={(e) => {
            setThemeValidationError(null)
            updateTheme({ name: e.target.value })
          }}
          data-form-type="other" // Prevents password managers treating this as a name
          data-testid="custom-theme-name-input"
        />
        {themeValidationError && (
          <FormErrorMessage>{themeValidationError}</FormErrorMessage>
        )}
      </FormControl>
      <Accordion allowToggle>
        {/* FONTS */}
        <AccordionItem>
          <ThemeAccordionButton
            label="Fonts"
            icon={duotone('font')}
            data-testid="custom-theme-fonts"
          />
          <AccordionPanel {...accordionPanelStyles}>
            <Stack>
              <FormControl mb="2">
                <FormLabel>
                  <Text>Title & heading font</Text>
                </FormLabel>
                <FontPicker
                  value={theme.headingFont}
                  updateValue={(font) => updateTheme({ headingFont: font })}
                  weight="bold"
                  defaultFont={DEFAULT_FONTS.headingFont}
                  data-testid="custom-theme-heading-font-picker"
                />
              </FormControl>
              <FormControl>
                <FormLabel>
                  <Text>Body font</Text>
                </FormLabel>
                <FontPicker
                  value={theme.bodyFont}
                  updateValue={(font) => updateTheme({ bodyFont: font })}
                  weight="normal"
                  defaultFont={DEFAULT_FONTS.bodyFont}
                  data-testid="custom-theme-body-font-picker"
                />
              </FormControl>
            </Stack>
          </AccordionPanel>
        </AccordionItem>

        {/* COLORS */}
        <AccordionItem>
          <ThemeAccordionButton
            label="Colors"
            icon={duotone('droplet')}
            data-testid="custom-theme-colors"
          />
          <AccordionPanel {...accordionPanelStyles}>
            <Stack>
              {/* HEADING COLOR */}
              <TextColorFormControl
                label="Title & heading color"
                value={theme.config?.headingColor || null}
                defaultValue={
                  isDark ? DEFAULT_HEADING_COLOR_DARK : DEFAULT_HEADING_COLOR
                }
                updateValue={updateHeadingColor}
                contrastValue={cardBackgroundColor}
                data-testid="custom-theme-body-color-picker"
              />
              {/* BODY COLOR */}
              <TextColorFormControl
                label="Body color"
                value={theme.config?.bodyColor || null}
                defaultValue={
                  isDark ? DEFAULT_BODY_COLOR_DARK : DEFAULT_BODY_COLOR
                }
                updateValue={updateBodyColor}
                contrastValue={cardBackgroundColor}
              />
              <TextColorFormControl
                label="Accent color"
                value={theme.accentColor || null}
                defaultValue={DEFAULT_ACCENT_COLOR}
                updateValue={updateAccentColor}
                helperText="This color will be applied to elements like links, footnotes, and blockquotes."
                data-testid="custom-theme-accent-color-picker"
              />
            </Stack>
            <Alert fontSize="xs" mt="6" colorScheme="gray">
              <AlertIcon />
              <Text>
                Note: these colors may be lightened or darkened for contrast and
                accessibility.
              </Text>
            </Alert>
          </AccordionPanel>
        </AccordionItem>

        {/* CARD STYLES */}
        <AccordionItem>
          <ThemeAccordionButton
            label="Card style"
            icon={duotone('rectangle-history')}
            data-testid="custom-theme-card-style"
          />
          <AccordionPanel {...accordionPanelStyles}>
            <ContainerStylePanel
              container={theme.config?.container || DEFAULT_CONTAINER}
              background={background || DEFAULT_THEME_BACKGROUND}
              docBackground={DEFAULT_DOC_BACKGROUND}
              enableBackgroundSizes={false}
              theme={theme}
              updateAttributes={updateThemeConfig}
              disableEffects={isGammaOrgUser ? [] : [ContainerEffect.CLEAR]}
            />
          </AccordionPanel>
        </AccordionItem>

        {/* BACKGROUND */}
        <AccordionItem>
          <ThemeAccordionButton
            label="Background"
            icon={duotone('swatchbook')}
            data-testid="custom-theme-background"
          />
          <AccordionPanel {...accordionPanelStyles}>
            <BackgroundPanel
              updateAttributes={updateThemeConfig}
              background={background}
              defaultMessage={
                <Alert>
                  <AlertIcon />
                  Choose an option above to set a default background for all{' '}
                  {DOC_DISPLAY_NAME_PLURAL} using this theme.
                </Alert>
              }
              isDark={isThemeDark(theme)}
            />
          </AccordionPanel>
        </AccordionItem>

        {/* LOGO */}
        <AccordionItem>
          <ThemeAccordionButton
            label="Logo"
            icon={theme.logoUrl ? duotone('cat-space') : duotone('cat')}
            data-testid="custom-theme-logo"
          />
          <AccordionPanel {...accordionPanelStyles}>
            <FormControl>
              <LogoPicker
                value={theme.logoUrl || null}
                updateValue={(src) =>
                  updateTheme({ logoUrl: src } as Partial<Theme>)
                }
              />
            </FormControl>
          </AccordionPanel>
        </AccordionItem>

        {/* GAMMA USERS */}
        {isGammaOrgUser && (
          <AccordionItem border="3px dashed var(--chakra-colors-sunglow-500)">
            <ThemeAccordionButton label="Advanced" icon={duotone('gear')} />
            <AccordionPanel {...accordionPanelStyles}>
              <CSSinJSFormControl
                label="Content CSS"
                initialValue={theme.config?.contentStyles}
                updateValue={(json) =>
                  updateThemeConfig({ contentStyles: json })
                }
              />
              <CSSinJSFormControl
                label="Container CSS"
                initialValue={theme.config?.containerStyles}
                updateValue={(json) =>
                  updateThemeConfig({ containerStyles: json })
                }
              />
            </AccordionPanel>
          </AccordionItem>
        )}
      </Accordion>
    </Stack>
  )
}

import { SystemStyleObject } from '@chakra-ui/react'

const CELL_ROOT_SELECTOR = '> [data-node-view-wrapper] > *'

export const SmartLayoutStyles: SystemStyleObject = {
  '.block-smartLayout': {
    // Prevent NodeViewContent divs from affecting grid/flex layouts
    '[data-node-view-content-outer="smartLayout"]': {
      display: 'contents',
    },
    '[data-node-view-content-inner="smartLayout"]': {
      display: 'contents',
    },
  },
  '.node-smartLayoutCell': {
    // Prevent NodeViewWrapper divs from affecting grid/flex layouts
    display: 'contents',
    '> [data-node-view-wrapper]': {
      display: 'contents',
    },

    // Add focus ring. Todo: add more control over positioning this
    '&.is-focused-inside': {
      [CELL_ROOT_SELECTOR]: {
        borderRadius: 'var(--block-border-radius)',
        shadow: 'outline',
      },
    },

    [CELL_ROOT_SELECTOR]: {
      // Ensures + button is relative to the cell root
      position: 'relative',
    },
  },
}

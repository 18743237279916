import {
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
  Text,
} from '@chakra-ui/react'

import { ColorPickerInput } from 'modules/tiptap_editor/components/panels/ColorPanel'

type TextColorFormControlProps = {
  label: string
  helperText?: string
  value: string | null
  defaultValue: string
  updateValue: (value: string) => void
  contrastValue?: string // Readability will be checked against this value if provided
} & FormControlProps

export const TextColorFormControl = ({
  label,
  helperText,
  value,
  defaultValue,
  updateValue,
  contrastValue,
  ...controlProps
}: TextColorFormControlProps) => {
  // const _isReadable =
  //   !contrastValue || isColorReadable(value || defaultValue, contrastValue)

  return (
    <FormControl {...controlProps}>
      <FormLabel>
        <Text>{label}</Text>
      </FormLabel>
      <ColorPickerInput
        value={value}
        updateValue={updateValue}
        defaultValue={defaultValue}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

import {
  Button,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { SUPPORTED_FONTS } from './supportedFonts'

type FontPickerProps = {
  value: string | undefined
  updateValue: (val: string | undefined) => void
  weight: string
  defaultFont: string
} & MenuButtonProps

export const FontPicker = ({
  value,
  updateValue,
  weight = 'regular',
  defaultFont,
  ...buttonProps
}: FontPickerProps) => {
  const onChange = (val: string) => updateValue(val || undefined)

  return (
    <Menu isLazy strategy="fixed">
      <MenuButton
        size="lg"
        as={Button}
        borderRadius="md"
        rightIcon={
          <FontAwesomeIcon
            icon={regular('chevron-down')}
            transform="shrink-6"
          />
        }
        variant="toolbar"
        px={4}
        h={10}
        w="100%"
        border="1px solid"
        borderColor="gray.200"
        {...buttonProps}
      >
        <Text
          textAlign="left"
          fontFamily={value || defaultFont}
          fontWeight={value ? weight : 'normal'}
          color={value ? 'black' : 'gray.400'}
        >
          {value || `${defaultFont} (Gamma default)`}
        </Text>
      </MenuButton>
      <MenuList w="xs" maxH="300px" overflow="auto" zIndex="dropdown">
        <MenuOptionGroup type="radio" value={value || ''} onChange={onChange}>
          <MenuItemOption value={''}>
            <Text
              fontFamily={defaultFont}
              fontSize="lg"
              fontWeight={weight}
              color="gray.400"
            >
              Gamma default
            </Text>
          </MenuItemOption>
          {SUPPORTED_FONTS.map((font) => {
            return (
              <MenuItemOption key={font} value={font}>
                <Text fontFamily={font} fontSize="lg" fontWeight={weight}>
                  {font}
                </Text>
              </MenuItemOption>
            )
          })}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}

import { SystemStyleObject } from '@chakra-ui/react'

import { FontSizeOptions } from './constants'

const headingStyles: SystemStyleObject = {
  fontWeight: 'bold',
  lineHeight: 1.25,
  fontFamily: 'var(--heading-font)',
  color: 'var(--heading-color)',
  // Todo: Gradient CSS
  // Todo: heading placeholders
}

export const fontStyles = {}
Object.entries(FontSizeOptions).forEach(([key, { size, heading, title }]) => {
  const css: SystemStyleObject = {
    fontSize: `${size}em`,
    ...(heading || title ? headingStyles : {}),
  }
  fontStyles[`[data-font-size="${key}"]`] = css
})

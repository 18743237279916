import { css, Theme } from '@chakra-ui/react'
import { Global, Interpolation } from '@emotion/react'
import React, { memo } from 'react'

import {
  CARD_CONTENT_CLASS,
  CARD_WRAPPER_CLASS,
} from 'modules/tiptap_editor/extensions/Card'
import {
  MediaMoveableStyles,
  MOVEABLE_WRAPPER_CLASSNAME,
} from 'modules/tiptap_editor/extensions/media'
import { CommentStyles } from 'modules/tiptap_editor/styles/commentStyles'
import {
  CardContentStyles,
  CardWrapperStyles,
} from 'modules/tiptap_editor/styles/contentStyles'

import { COMMENTS_WRAPPER_CLASSNAME } from '../Annotatable/components/BlockCommentsStack/components/BlockCommentsWrapper'

export const GlobalCardStyles = memo(() => {
  return (
    <Global
      styles={(theme: Theme) => {
        const globalStyles = {
          [`.${CARD_CONTENT_CLASS}`]: CardContentStyles,
          [`.${CARD_WRAPPER_CLASS}`]: CardWrapperStyles,
          [`.${COMMENTS_WRAPPER_CLASSNAME}`]: CommentStyles,
          [`.${MOVEABLE_WRAPPER_CLASSNAME}`]: MediaMoveableStyles,
        }
        if (!globalStyles) return undefined
        // @ts-ignore
        const styles = css(globalStyles)(theme)
        return styles as Interpolation<{}>
      }}
    />
  )
})
GlobalCardStyles.displayName = 'GlobalCardStyles'
